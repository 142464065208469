import React from 'react';
import partyImg from '../images/dumbleApplause.webp';
// oder du machst hier ein Bild/Gif deiner Wahl rein :)

function Hooray() {
    function refreshPage() {
        window.location.reload(false);
    }

    // Hier kommt deine GlückwunschMessage rein :)
    const ChallengeWonMessage = <>
        Hoooooray :) <br/>
        My mighty dragon has won the challenge and I am extremly proud of him.<br/>
        As a small price, the dragon may make a wish :) <br/><br/>
        Lava you, <br/>
        Curly
    </>

    return (
        <div className="popUp-overlay">
            <div className="hooray-content">
                <button className="close-popUp-btn" onClick={refreshPage}>
                    <i className="fa fa-times fa-2x" aria-hidden="true"></i>
                </button>
                <h3 className="hooray-title">You Won The Challenge!</h3>
                <img className="hooray-img" src={partyImg} width="300px" height="300px" alt="party-img" />
                <p>{ChallengeWonMessage}</p>
            </div>
        </div>
    )
}

export default Hooray;