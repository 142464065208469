import React from 'react';

// this PopUp can be used anywhere, at the moment is used in the Newsletter-pages. 
// It contains a message, passed through by props
// and a button that reloads the page when clicked.

function PopUpReload (props) {

    function refreshPage() {
        window.location.reload(false);
      }

    return (
        <div className="popUp-overlay">
            <div className="popUp-content">
                <button className="close-popUp-btn" onClick={refreshPage}>
                    <i className="fa fa-times fa-2x" aria-hidden="true"></i>
                </button>
                <p>{props.message}</p>
            </div>
        </div>
    )
}

export default PopUpReload;