import React from 'react';


function TotalBox(props) {

    let totalDays = 0;
    let totalHours = 0;
    let hoursPerDay = 0;

    function calculateTotals(time, days) {
        // loops through all time-entries and adds their value to get the totalHours
        time.forEach(element => {
        let oneTimeEntry = parseFloat(element.time)
        totalHours = totalHours + oneTimeEntry;
        });
        // loops through all day-entries and adds one to the totalDays var for every entry in the db
        days.forEach(element => {
            totalDays = totalDays + 1
        });
    }

    calculateTotals(props.time, props.days)
        // console.log("TotalDays after loops: " + totalDays)
        // console.log("TotalHours after loops: " + totalHours)
    // calculates the average time spent coding per day
    if(totalDays !== 0 && totalHours !== 0) {
        let averageTime = totalHours / totalDays;
        hoursPerDay = Math.round(averageTime * 10) / 10;
    }

    return (
        <div className="total-box">
            <p className="box-title">Motivational Overview</p>
            <p className="box-subtitle">since 1st January of 2021</p>

            <div className="row total-row" style={{width:80 + "%", marginLeft:"auto", marginRight:"auto"}}>
                <div className="total-container col-xl-6 col-lg-6 col-md-12 col-sm-6">
                    <p className="total-result">{totalDays}</p>
                    <p className="total-description">Days</p> 
                </div>
                <div className="total-container col-xl-6 col-lg-6 col-md-12 col-sm-6">
                    <p className="total-result">{totalHours}</p>
                    <p className="total-description">Hours</p> 
                </div>
                <div className="total-container col-xl-6 col-lg-6 col-md-12 col-sm-6">
                    <p className="total-result">{hoursPerDay}</p>
                    <p className="total-description">hours/day<br/>on Average</p> 
                </div>
            </div>
        </div>
    )
}

export default TotalBox;