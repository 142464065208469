import React, {useState} from 'react';
import {useFormik} from 'formik';
import axios from "axios";
import partyLeft from '../images/party-emoji-left.png';
import partyRight from '../images/party-emoji-right.png';
import PopUpReload from './PopUpReload';
import Hooray from './Hooray';

function InputBox(props) {
    let missingDays = (100 - props.allEntries.length);
    // uncomment missingDays=1 to be able to see and work on Hooray.jsx
    // let missingDays = 1
    const successMessage = <>
        You successfully recorded another day spent Coding. <br/>
        Congratulations! Only {missingDays -1} days left to go!
    </>
    const existingDateMessage = <>
        You already worked on the challenge on this specific day. <br/>
        Please select another day before submitting the form.
    </>
    const unauthorizedMessage = <>
        Oops! seems like the password was wrong. <br/>
        Maybe try again?
    </>

    const [isNotClicked, setIsNotClicked] = useState(true)
    const [showPopUp, setShowPopUp] = useState(false);
    const [dbMessage, setDbMessage] = useState(null)
    //set finishedChallenge back to false if Hooray-Bearbeitung is finished
    const [finishedChallenge, setFinishedChallenge] = useState(false)
    
    const formik = useFormik({
        initialValues: {  
          date: '',  
          time: '',
          password: '',   
        },  
        onSubmit: (values, {resetForm}) => {
            console.log(values)
            // make a post request to the backend to put data from input form into database
            axios.post('/new-entry', values)
            .then(function (response) {
                console.log(response);
                resetForm();

                if(response.status === 201) {
                    if (missingDays -1 === 0) {
                        setFinishedChallenge(true)
                        missingDays = null
                    } else {
                        setDbMessage(successMessage)
                        setShowPopUp(true)
                    }
                }                
            })
            .catch(function (error) {
                // handle error
                console.log(error);
                if (error.response) {
                    if (error.response.status === 409) {
                        setDbMessage(existingDateMessage)
                        setShowPopUp(true)
                    } else if (error.response.status === 401) {
                        setDbMessage(unauthorizedMessage)
                        setShowPopUp(true)
                    }
                }
            });
            setIsNotClicked(true)
        },  
    });

    function buttonClick() {
        setIsNotClicked(false);
        console.log("You clicked the first button!")
    }

    return (
        <div className="input-box">
            {finishedChallenge ? <Hooray onClose={() => {setShowPopUp(false)}}/> : null}
            <img className="party-img-left" src={partyLeft} alt="partyLeft" />
            <p className="box-title">
                I completed yet another Day of the Challenge!  
            </p>
            <img className="party-img-right" src={partyRight} alt="partyLeft"/>

            <form onSubmit={formik.handleSubmit}>
            <div className="mb-3">
                {/* Add number of next Challenge day responsively? possible? */}
                <label htmlFor="date" className="form-label">Date of Day Spent Coding</label>
                <input
                    // id="date"
                    name="date"
                    type="date"
                    className="form-control"
                    onChange={formik.handleChange}
                    value={formik.values.date}
                    required
                />
            <div/>
            <div className="mb-3"/>
                <label htmlFor="time" className="form-label">Time Spent on Programming (in&nbsp;hours)</label>
                <input
                    // id="time"
                    name="time"
                    type="number"
                    min="1"
                    step="0.25"
                    className="form-control"
                    onChange={formik.handleChange}
                    value={formik.values.time}
                    required
                />
            </div>
            {isNotClicked ?
            <>
            <button type="button" className="btn btn-dark" onClick={buttonClick}>Submit</button>
            </>
            :
            <>
                <div className="mb-3">
                    <label htmlFor="password" className="form-label">And now tell me the password</label>
                    <input
                        // id="password"
                        name="password"
                        type="password"
                        className="form-control"
                        onChange={formik.handleChange}
                        value={formik.values.password}
                        required
                    />
                </div>
                <button type="submit" className="btn btn-dark" onClick={buttonClick}>Submit</button>
            </>}
            {/* ternary operator: if the form is submitted (visible through the state of the isSubmitted const)
            then show the PopUpReload-Component,
            if the form is not submitted, show nothing here. */}
            {showPopUp ? <PopUpReload message={dbMessage} onClose={() => {setShowPopUp(false); setFinishedChallenge(false)}}/> : null}
            </form>
        </div>
    )
}

export default InputBox;