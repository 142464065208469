import React, {useEffect, useState} from 'react';
import axios from 'axios';

import DayBox from './components/DayBox';
import InputBox from './components/InputBox';
import TotalBox from './components/TotalBox';

function App() {
  axios.defaults.baseURL = 'https://api.challenge.cuddledragon.com';

  let dayBoxNumbers = [];
  let i = 0;
  for (i = 0; i < 100; i++) {
    dayBoxNumbers.push(i)
  }
  // console.log(dayBoxNumbers)

  // Test to get everything from the home-root of backend
  const [allEntries, setAllEntries] = useState([]);
  useEffect(() => {
    axios.get("/all")
    .then(function(response) {
      setAllEntries(response.data.sort((a, b) => {
        // Sort by date
        if(a.date < b.date) {
          return -1;
        } else if (a.date > b.date) {
          return 1;
        } else {
          return 0;
        }
        }).map((d, index) => ({
        ...d,
        day_id: index +1
      })))
    })
    .catch(function (error) {
      // handle error
      console.log(error);
    })
  }, [])
  console.log(allEntries)

  const [totalTime, setTotalTime] = useState([])
  // BackendCall to get only the time entries from the db, in order to calculate totalHours variable
  // the totalTime array is then passed via props to the TotalBox-component to avoid double http-requests
  useEffect(() => {
      axios.get("/total-time")
      .then(function(response) {
          setTotalTime(response.data)            
      })
      .catch(function (error) {
          // handle error
          console.log(error);
      })
  }, [])

  const [totalDays, setTotalDays] = useState([])
  // BackendCall to get only the date entries from the db, in order to calculate totalDays variable
  // the totalDays array is then passed via props to the TotalBox-component to avoid double http-requests
  useEffect(() => {
    axios.get("/total-days")
    .then(function(response) {
        setTotalDays(response.data)            
    })
    .catch(function (error) {
        // handle error
        console.log(error);
    })
  }, [])

  // create an Array to put data in the 100-Boxes
  // getMissingDays-function creates a partialArray that fills gap from lastEntry in db to 100, so that no box is empty
  // allDaysArray is complete array that holds all db-entries and fills up to 100 with missingArray 
  const getMissingDays = (start, max) => {
    const missingDays = [];
    for(let i = start; i <= max; i++) {
      missingDays.push({
        day_id: i,
        time: "¯\\(°_o)/¯",
        date: "date"
      })
    }
    return missingDays;
  }
  const missing = getMissingDays(allEntries.length + 1, 100);
  //concatenation from the two partial arrays
  const allDays = [
    ...allEntries,
    ...missing
  ]

  return (
    <div className="App">
    {/* HEADER SECTION WITH NAV-BAR */}
      <nav>
        <h1>100 Days Coding Challenge</h1>
        <p id="page-description">Code 1h minimum a day for 100 days in 2021! Can you do it?</p>
      </nav>

      {/* MAIN PAGE CONTENT */}
      <div className="main container-fluid">
        <div className="row">

          {/* 1/3 INFO-BOXES PART OF THE PAGE -- ONLY VISIBLE ON SM & XS SCREENS */}
          <div id="input-and-total" className="col-lg-4 col-md-4 col-sm-12 d-block d-md-none">
            <div className="sticky-class">
              <InputBox allEntries={allEntries} />
              <TotalBox time={totalTime} days={totalDays} />
            </div>
          </div>

          {/* 2/3 DAY-BOXES PART OF THE PAGE */}
          <div id="day-boxes" className="col-lg-8 col-md-8 col-sm-12">
            {/* 100 DAY BOXES  */}
            <div className="row">
              {allDays.map((day,index) =>
                <DayBox key={index} dayBoxNumber={day.day_id} date={day.date} time={day.time} />
              )}
            </div>
          </div>

          {/* 1/3 INFO-BOXES PART OF THE PAGE -- ONLY VISIBLE ON MD & LG SCREENS */}
          <div id="input-and-total" className="col-lg-4 col-md-4 col-sm-12 d-none d-md-block">
            <div className="sticky-class">
              <InputBox allEntries={allEntries} />
              <TotalBox time={totalTime} days={totalDays} />
            </div>
          </div>
        {/* END OF MAIN ROW */}
        </div>
      </div>

    {/* FOOTER SECTION */}
      <footer>
        <p>Copyright ©2021 by Viktoria Jakobs</p>
      </footer>
    </div>
  );
}

export default App;
