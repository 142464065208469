import React from 'react';

function DayBox(props) {

    return (
        <div className="dayBox-margin col-lg-3 col-md-4 col-sm-4 col-xs-6">
            <div className="day-box">
                {/* ternary operator to display emptyCheckboxItem when no entry for that box has been made
                and to display checkedCheckboxIcon when an entry has been made */}
                {props.date === "date"
                    ? <i className="fa fa-square-o fa-4x empty-checkbox" aria-hidden="true"></i>
                    : <i className="fa fa-check-square-o fa-4x empty-checkbox" aria-hidden="true"></i>
                }
                <p className="box-p">Day {props.dayBoxNumber}</p>
                <p className="box-p">{props.date}</p>
                <p className="box-p">{props.time} h</p>
            </div>
        </div>
    )
}

export default DayBox;